<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 bg-white">
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        {{ $t("DetallesSubCliente") }}
      </h2>
    </div>
    <!-- form -->
    <validation-observer ref="editClient">
      <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
        <b-row>
          <b-col cols="6" class="mb-2 auth-create-form">
            <b-form-group label-for="client-image">
              <ImageDropzone v-if="clientPrepare" ref="image" :files="files" max-files="1"
                :label="'AddImageSubClient'" />
            </b-form-group>
          </b-col>

          <b-col cols="6" class="mb-2 auth-create-form">
            <!-- about -->
            <validation-provider #default="{ errors }" :name="$t('Nombre')" rules="required">
              <b-form-group :label="$t('Nombre')" label-for="client-name">
                <b-form-input v-model="client.name" name="name" :placeholder="$t('Nombre')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <validation-provider #default="{ errors }" :name="$t('LegalName')">
              <b-form-group :label="$t('LegalName')" label-for="client-legalName">
                <b-form-input v-model="client.legal_name" name="legal_name" :placeholder="$t('LegalName')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <validation-provider #default="{ errors }" :name="$t('CIF')">
              <b-form-group :label="$t('CIF')" label-for="client-cif">
                <b-form-input v-model="client.cif" name="reference" :placeholder="$t('CIF')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- contact -->
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Telefono')">
              <b-form-group :label="$t('Telefono')" label-for="client-phone">
                <b-form-input v-model="client.phone" name="reference" :placeholder="$t('Telefono')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Email')" rules="email">
              <b-form-group :label="$t('Email')" label-for="client-email">
                <b-form-input v-model="client.email" name="reference" :placeholder="$t('Email')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Web')">
              <b-form-group :label="$t('Web')" label-for="client-web">
                <b-form-input v-model="client.web" name="reference" :placeholder="$t('Web')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- address -->
          <b-col sm="6">
            <b-form-group :label="$t('Province')" label-for="province">
              <BaseSelect :resource="'/provinces'" @input="
          [(province = $event), (town = ''), (townsKey = new Date())]
          " :value="province" :label="'Provincia'" :clearable="!town" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('Towns')" label-for="town">
              <BaseSelect :resource="'/towns'" @input="town = $event" :value="town"
                :httpBody="{ province_id: province.id }" :label="'Municipio'" :readonly="!province" :key="`${townsKey}`"
                :clearable="true" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Direccion')">
              <b-form-group :label="$t('Direccion')" label-for="client-address">
                <b-form-input v-model="client.address" name="reference" :placeholder="$t('Direccion')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('CodigoPostal')">
              <b-form-group :label="$t('CodigoPostal')" label-for="client-postalCode">
                <b-form-input v-model="client.postalCode" name="reference" :placeholder="$t('CodigoPostal')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12 text-right">
            <b-button type="submit" variant="primary" class="mt-2 mr-1">
              {{ $t("Guardar") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    ImageDropzone,
    BaseSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      townsKey: new Date(),

      roles: "",
      companies: "",
      clients: [],

      files: [],
      required,
      email,
      clientPrepare: false,
      disabledClients: true,

      province: "",
      town: "",
    };
  },
  computed: {
    ...mapGetters({
      client: "subclients/getSubClient",
      currentRole: "auth/getRole",
      currentCompany: "companies/getCurrentCompany",

      currentClient: "clients/getCurrentClient",
      selectCompanies: "companies/getListCompanies",
      selectClients: "clients/getSelectClients",
    }),
  },
  watch: {
    companies() {
      this.clients = [];

      if (this.companies) {
        this.searchClients({
          company: this.companies.id,
          client: this.currentClient,
        });
        this.disabledClients = false;
      } else {
        this.disabledClients = true;
      }
    },
    clients() {
      this.departments = [];

      const aux = this;
      if (this.clients.length > 0) {
        aux.selectDepartments({ clients: this.clients });
      } else {
        this.setSelectDepartments([]);
      }
    },
  },
  methods: {
    ...mapActions({
      edit: "subclients/edit",
      getSubClient: "subclients/getSubClient",
      searchCompanies: "companies/searchCompanies",
      searchClients: "clients/selectClients",
      getRoles: "users/getRoles",
    }),
    ...mapMutations({
      setSelectDepartments: "departments/setSelectDepartments",
    }),
    setClients() {
      this.companies = this.currentCompany;
      this.clients = this.currentClient;
    },
    handleSubmit() {
      this.$refs.editClient.validate().then((success) => {
        if (success) {
          const { id } = this.client;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, user: formData });
          }
        }
      });
    },
    setData() {
      if (this.client.image) {
        const file = {
          name: "Image",
          url: this.client.image.path,
          urlDelete: `/subclients/delete_image/${this.client.id}`,
        };
        this.files.push(file);
      }

      if (this.client.province) {
        this.province = this.client.province;
      }

      if (this.client.town) {
        this.town = this.client.town;
      }

      this.clientPrepare = true;
    },
    createFormData() {
      const data = this.$refs.image.getFormData("image");
      //about
      data.append("name", this.client.name);
      data.append("legal_name", this.client.legal_name);
      data.append("cif", this.client.cif ? this.client.cif : "");
      //contact
      data.append("phone", this.client.phone ? this.client.phone : "");
      data.append("email", this.client.email ? this.client.email : "");
      data.append("web", this.client.web ? this.client.web : "");
      //address
      data.append("province_id", this.province?.id ? this.province?.id : "");
      data.append("town_id", this.town.id ? this.town.id : "");

      data.append("address", this.client.address ? this.client.address : "");

      data.append(
        "postalCode",
        this.client.postalCode ? this.client.postalCode : ""
      );

      if (this.companies) {
        data.append("companies[]", this.companies.id);
      }
      data.append("client_id", this.client.client.id);

      return data;
    },
  },

  async created() {
    await this.getSubClient(this.$route.params.id);
    await this.searchCompanies();
    await this.setClients();
    await this.setData();
  },
};
</script>
