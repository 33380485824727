var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 bg-white",attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})],1)],1),_vm._v(" "+_vm._s(_vm.$t("DetallesSubCliente"))+" ")])]),_c('validation-observer',{ref:"editClient"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 auth-create-form",attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"client-image"}},[(_vm.clientPrepare)?_c('ImageDropzone',{ref:"image",attrs:{"files":_vm.files,"max-files":"1","label":'AddImageSubClient'}}):_vm._e()],1)],1),_c('b-col',{staticClass:"mb-2 auth-create-form",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"client-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('LegalName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('LegalName'),"label-for":"client-legalName"}},[_c('b-form-input',{attrs:{"name":"legal_name","placeholder":_vm.$t('LegalName')},model:{value:(_vm.client.legal_name),callback:function ($$v) {_vm.$set(_vm.client, "legal_name", $$v)},expression:"client.legal_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('CIF')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CIF'),"label-for":"client-cif"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('CIF')},model:{value:(_vm.client.cif),callback:function ($$v) {_vm.$set(_vm.client, "cif", $$v)},expression:"client.cif"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Telefono')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Telefono'),"label-for":"client-phone"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Telefono')},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"client-email"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Email')},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Web')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Web'),"label-for":"client-web"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Web')},model:{value:(_vm.client.web),callback:function ($$v) {_vm.$set(_vm.client, "web", $$v)},expression:"client.web"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Province'),"label-for":"province"}},[_c('BaseSelect',{attrs:{"resource":'/provinces',"value":_vm.province,"label":'Provincia',"clearable":!_vm.town},on:{"input":function($event){[(_vm.province = $event), (_vm.town = ''), (_vm.townsKey = new Date())]}}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Towns'),"label-for":"town"}},[_c('BaseSelect',{key:("" + _vm.townsKey),attrs:{"resource":'/towns',"value":_vm.town,"httpBody":{ province_id: _vm.province.id },"label":'Municipio',"readonly":!_vm.province,"clearable":true},on:{"input":function($event){_vm.town = $event}}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Direccion')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Direccion'),"label-for":"client-address"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Direccion')},model:{value:(_vm.client.address),callback:function ($$v) {_vm.$set(_vm.client, "address", $$v)},expression:"client.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CodigoPostal')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CodigoPostal'),"label-for":"client-postalCode"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('CodigoPostal')},model:{value:(_vm.client.postalCode),callback:function ($$v) {_vm.$set(_vm.client, "postalCode", $$v)},expression:"client.postalCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Guardar"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }